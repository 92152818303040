import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import Logo from '../assets/logo.png'

const footerStrings = {
  madeWithLove: {
    "en": 'Made with 💙 in Brazil',
    "pt": 'Feito com 💙 no Brasil'
  },
  copyright: {
    "en": '© blocos, 2022. All rights reserved.',
    "pt": '© blocos, 2022. Todos os direitos reservados.',
  },
  terms: {
    "en": 'Terms',
    "pt": 'Termos',
  },
  privacy: {
    "en": 'Privacy',
    "pt": 'Privacidade',
  },
  download: {
    "en": 'Download Now',
    "pt": 'Baixe Agora',
  },
  needHelp: {
    "en": 'Do you need help?',
    "pt": 'Preicsa de ajuda?',
  },
  contact: {
    "en": 'Talk to Us',
    "pt": 'Entre em Contato',
  },
}

const Footer = ({locale}) => {
  const lang = locale || "en";
  return (
    <FooterContainer>
      <FooterLinksWrapper>
        <FooterDescription>
          <Link to="/"><img src={Logo} alt="Logo" /></Link>
          <p>{footerStrings.madeWithLove[lang]}</p>
          <p>{footerStrings.copyright[lang]}</p>
        </FooterDescription>
        <FooterLinkItems>
          <FooterLinkTitle>Legal</FooterLinkTitle>
          <FooterLink to="/terms">{footerStrings.terms[lang]}</FooterLink>
          <FooterLink to="/privacy">{footerStrings.privacy[lang]}</FooterLink>
        </FooterLinkItems>
      </FooterLinksWrapper>
      <FooterLinksWrapper>
        <FooterLinkItems>
          <FooterLinkTitle>{footerStrings.download[lang]}</FooterLinkTitle>
          <FooterLink as="a" href="https://play.google.com/store/apps/details?id=com.soneto.blocos" rel="noopener">Android App</FooterLink>
          <FooterLink as="a" href="https://apps.apple.com/us/app/id1519636469" rel="noopener">iPhone App</FooterLink>
        </FooterLinkItems>
        <FooterLinkItems>
          <FooterLinkTitle>{footerStrings.needHelp[lang]}</FooterLinkTitle>
          <FooterLink as="a" href="mailto:contact@blocos.co">{footerStrings.contact[lang]}</FooterLink>
        </FooterLinkItems>
      </FooterLinksWrapper>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.div`
  padding: 5rem calc((100vw - 1100px) / 2);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: #fff;
  background: #463796;
  
`

const FooterDescription = styled.div`
  padding: 0 2rem;
  font-size: 12px;

  img {
    margin-bottom: 0.5rem;
    width: 130px;
  }

  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
`

const FooterLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;

  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
  }
`

const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem;

  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
`

const FooterLinkTitle = styled.h2`
  font-size: 14px;
  margin-bottom: 16px;
`

const FooterLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: bold;
  color: #999DF1;

  &:hover {
    color: #C32B19;
    transition: 0.3s ease-out;
  }
`
