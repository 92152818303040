/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Footer from "./footer"
import Header from "./header"
import {GlobalStyle} from "./styles/global-styles"

const Layout = ({children, lang}) => {

  return (
    <>
      <GlobalStyle />
      <Header locale={lang} />
      <main>{children}</main>
      <Footer locale={lang} />
    </>
  )
}


export default Layout