import * as React from "react"
import {Link} from "gatsby"
import styled from "styled-components"
import {menuData} from '../data/menu-data'
import Logo from '../assets/logo.png'
// import {Button} from "./button"

const Header = ({locale}) => {
  const lang = locale || "en";
  return (
    <Nav>
      <NavLink to="/"><img src={Logo} alt="logo" width="110px" /></NavLink>
      <NavMenu>
        {menuData.map((item, index) => (
          item.link.startsWith('/') ?
            <NavLink to={item.link} key={index}>
              {item.title[lang]}
            </NavLink>
            : <NavLink as="a" href={item.link} key={index}>
              {item.title[lang]}
            </NavLink>
        ))}
        {/* <NavBtn>
          <Button primary="false" to="/#download-app-section">Baixar App</Button>
        </NavBtn> */}
      </NavMenu>

    </Nav>
  )
}


export default Header

const Nav = styled.nav`
  background: #253066;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1300px) / 2);
  z-index: 100;
  position: relative;
`

const NavLink = styled(Link)`
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: #C32B19;
    transition: 0.3s ease-out;
  }
`

const NavMenu = styled.div`
  display: flex;
  align-items: center;
`

// const NavBtn = styled.div`
//   display: flex;
//   align-items: center;
//   margin-right: 24px;
// `

