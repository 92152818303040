import {createGlobalStyle} from "styled-components";
import styled from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Montserrat'; 
    font-weight: 400;
    font-style: regular;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background-color: #fff;
  }
`

export const InlineButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
export const ContentContainer = styled.div`
  width: 100vw;
  background: ${({background}) => (background ? background : '#000')};  
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: ${({textAlign}) => (textAlign ? textAlign : "center")};
  padding: 5rem calc((100vw - 1300px) / 2);
  height: 100%;
`
export const TopLine = styled.p`
  color: #8B5CF5;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1rem;
  margin-bottom: 0.75rem;
`
export const ContentHeader = styled.p`
  text-alignt: center;
  margin-bottom: ${({marginBottom}) => (marginBottom ? marginBottom : "1.5rem")};
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: clamp(1.8rem, 5vw, 2rem);
  font-weight: bold;

  span {
    color: ${({color}) => (color ? color : '#fff')};
    font-weight: bold;
  }
`
export const ContentDescription = styled.p`
  text-alignt: center;
  line-height: 1.3;
  color: #C0C0C0;
  padding-left: 2rem;
  padding-right: 2rem;
`
export const ContentWrapper = styled.div`
  max-width: ${({isLarge}) => (isLarge === "true" ? '1100px' : '680px')};
`